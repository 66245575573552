<script context="module">
    // import { defaultLocale } from '$lib/i18n';
</script>

<script lang="ts">
    import { getStores, navigating, page, updated } from '$app/stores';
    import { auth } from '$stores/auth';
    import { setLocale } from '$stores/master';

    $: setLocale($page.params.lang);
</script>

<slot />
